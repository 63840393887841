/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { BaseFileDto } from '../models/base-file-dto';
import { CommissionFeeAndTotalInfo } from '../models/commission-fee-and-total-info';
import { CommissionFeeRequest } from '../models/commission-fee-request';
import { SubmitCommissionFeeInvoiceRequest } from '../models/submit-commission-fee-invoice-request';

@Injectable({ providedIn: 'root' })
export class CommissionFeeService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `commissionFeePost()` */
  static readonly CommissionFeePostPath = '/commission-fee';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `commissionFeePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  commissionFeePost$Plain$Response(
    params?: {
      body?: CommissionFeeRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CommissionFeeAndTotalInfo>> {
    const rb = new RequestBuilder(this.rootUrl, CommissionFeeService.CommissionFeePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommissionFeeAndTotalInfo>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `commissionFeePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  commissionFeePost$Plain(
    params?: {
      body?: CommissionFeeRequest
    },
    context?: HttpContext
  ): Observable<CommissionFeeAndTotalInfo> {
    return this.commissionFeePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CommissionFeeAndTotalInfo>): CommissionFeeAndTotalInfo => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `commissionFeePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  commissionFeePost$Json$Response(
    params?: {
      body?: CommissionFeeRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CommissionFeeAndTotalInfo>> {
    const rb = new RequestBuilder(this.rootUrl, CommissionFeeService.CommissionFeePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommissionFeeAndTotalInfo>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `commissionFeePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  commissionFeePost$Json(
    params?: {
      body?: CommissionFeeRequest
    },
    context?: HttpContext
  ): Observable<CommissionFeeAndTotalInfo> {
    return this.commissionFeePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CommissionFeeAndTotalInfo>): CommissionFeeAndTotalInfo => r.body)
    );
  }

  /** Path part for operation `commissionFeeInvoiceGet()` */
  static readonly CommissionFeeInvoiceGetPath = '/commission-fee-invoice';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `commissionFeeInvoiceGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  commissionFeeInvoiceGet$Plain$Response(
    params?: {
      id?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BaseFileDto>> {
    const rb = new RequestBuilder(this.rootUrl, CommissionFeeService.CommissionFeeInvoiceGetPath, 'get');
    if (params) {
      rb.query('id', params.id, {"style":"form"});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BaseFileDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `commissionFeeInvoiceGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  commissionFeeInvoiceGet$Plain(
    params?: {
      id?: number;
    },
    context?: HttpContext
  ): Observable<BaseFileDto> {
    return this.commissionFeeInvoiceGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<BaseFileDto>): BaseFileDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `commissionFeeInvoiceGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  commissionFeeInvoiceGet$Json$Response(
    params?: {
      id?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BaseFileDto>> {
    const rb = new RequestBuilder(this.rootUrl, CommissionFeeService.CommissionFeeInvoiceGetPath, 'get');
    if (params) {
      rb.query('id', params.id, {"style":"form"});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BaseFileDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `commissionFeeInvoiceGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  commissionFeeInvoiceGet$Json(
    params?: {
      id?: number;
    },
    context?: HttpContext
  ): Observable<BaseFileDto> {
    return this.commissionFeeInvoiceGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<BaseFileDto>): BaseFileDto => r.body)
    );
  }

  /** Path part for operation `submitCommissionFeeInvoicePost()` */
  static readonly SubmitCommissionFeeInvoicePostPath = '/submit-commission-fee-invoice';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `submitCommissionFeeInvoicePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  submitCommissionFeeInvoicePost$Plain$Response(
    params?: {
      body?: SubmitCommissionFeeInvoiceRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BaseFileDto>> {
    const rb = new RequestBuilder(this.rootUrl, CommissionFeeService.SubmitCommissionFeeInvoicePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BaseFileDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `submitCommissionFeeInvoicePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  submitCommissionFeeInvoicePost$Plain(
    params?: {
      body?: SubmitCommissionFeeInvoiceRequest
    },
    context?: HttpContext
  ): Observable<BaseFileDto> {
    return this.submitCommissionFeeInvoicePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<BaseFileDto>): BaseFileDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `submitCommissionFeeInvoicePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  submitCommissionFeeInvoicePost$Json$Response(
    params?: {
      body?: SubmitCommissionFeeInvoiceRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BaseFileDto>> {
    const rb = new RequestBuilder(this.rootUrl, CommissionFeeService.SubmitCommissionFeeInvoicePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BaseFileDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `submitCommissionFeeInvoicePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  submitCommissionFeeInvoicePost$Json(
    params?: {
      body?: SubmitCommissionFeeInvoiceRequest
    },
    context?: HttpContext
  ): Observable<BaseFileDto> {
    return this.submitCommissionFeeInvoicePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<BaseFileDto>): BaseFileDto => r.body)
    );
  }

}
